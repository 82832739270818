<template>
  <div class="courts-price-list" :class="[{'fixed-draggable-dynamic-table-wrapper-height': $route.name === 'courtPriceList'}]">

    <draggable-dynamic-table ref="courtsPriceListTable"
                             :in-modal="true"
                             :active-grid="true"
                             :columns="columnsLabel"
                             :options="options"
                             @column:select="consoleLog($event)"
                             v-model="data"/>

    <!-- insert new user prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editCourtPricePromptStatus"
      @close="editCourtPricePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('editCourtPriceBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('courts.price.edit.dynamicTitle', {name: courtName}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editCourtPricePromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <template>
          <keep-alive>
            <edit-court-price :court-id="courtId" :in-modal="true" @insert="reloadCourtsList"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button id="editCourtPrice" v-show="false" @click="editCourtPricePromptStatus = true"/>
  </div>
</template>

<script>
  import axios from "axios";
  import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
  import CustomIcon from '../../../../../../components/customIcon/customIcon.vue'
  import EditCourtPrice from "../edit/editCourtsPrice.vue";
  import {getTimePrices} from '@/http/requests/club/timePrices'
  import {addComma} from "../../../../../../assets/js/functions";

  export default {
    name: 'CourtsPriceList',
    metaInfo() {
      return {
        title: this.$t('courts.price.list.title')
      }
    },
    components: {
      EditCourtPrice,
      CustomIcon,
      DraggableDynamicTable
    },
    props: {
      courtId: {
        type: [Number, String],
        default: 0
      },
      courtName: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        editCourtPricePromptStatus: false,
        loadingTimer: 0,
        options: {
          id: 'courtsPriceListTable',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'day_7',
            i18n: 'courts.price.table.header.friday',
            width: 'calc((100% / 10))',
            minWidth: 90,
          },
          {
            field: 'day_6',
            i18n: 'courts.price.table.header.thursday',
            width: 'calc((100% / 10))',
            minWidth: 90,
          },
          {
            field: 'day_5',
            i18n: 'courts.price.table.header.wednesday',
            width: 'calc((100% / 10))',
            minWidth: 90,
          },
          {
            field: 'day_4',
            i18n: 'courts.price.table.header.tuesday',
            width: 'calc((100% / 10))',
            minWidth: 90,
          },
          {
            field: 'day_3',
            i18n: 'courts.price.table.header.monday',
            width: 'calc((100% / 10))',
            minWidth: 90,
          },
          {
            field: 'day_2',
            i18n: 'courts.price.table.header.sunday',
            width: 'calc((100% / 10))',
            minWidth: 90,
          },
          {
            field: 'day_1',
            i18n: 'courts.price.table.header.saturday',
            width: 'calc((100% / 10))',
            minWidth: 90,
          },
          {
            field: 'termName',
            i18n: 'courts.price.table.header.termName',
            width: '150px',
            minWidth: 120,
            align: 'center',
          }
        ],
        data: [],
        filters: [`court=${this.courtId || this.$route.params.id}`],
        sorts: ['order[0]=term_id,asc'],
        page: 1,
        endedList: false,
        actions: [
          {
            toolbar: [
              {
                id: 'editCourtPrice',
                icon: 'EDIT',
                iconPack: 'useral',
                color: 'warning',
                permission: 'court.update'
              }
            ],
            leftToolbar: [
              {
                id: 'printTable',
                icon: 'PRINT',
                iconPack: 'useral'
              },
              {
                id: 'downloadTable',
                icon: 'DOWNLOAD',
                iconPack: 'useral'
              },
              {
                id: 'routeBackBTN',
                route: {name: this.$route.name === 'courtPriceList' ? 'court' : 'courts'},
                icon: 'CHEVRON_LEFT',
                iconPack: 'useral'
              }
            ]
          }
        ]
      }
    },
    created() {

      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      }, 50)
      this.$store.dispatch('setPageTitle', this.$t('courts.price.list.title'))

      this.getCourtPrices()
    },
    methods: {
      getCourtPrices() {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.courtsPriceListTable && this.data.length === 0) {
              this.$refs.courtsPriceListTable.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.courtsPriceListTable && this.data.length > 0) {
              this.$refs.courtsPriceListTable.loadMoreStatus = 'Loading'
            }

            getTimePrices(this.page, this.filters, this.sorts).then((res) => {
              this.endedList = res.data.data.length === 0
              let data = {}
              res.data.data.forEach((court) => {
                if (court.term) {
                  if (!data[`term_${court.term.id}`]) {
                    data[`term_${court.term.id}`] = {}
                  }
                  data[`term_${court.term.id}`]['termName'] = court.term.name || ''
                  data[`term_${court.term.id}`][`day_${court.week_day}`] = addComma(court.price)
                }
              })
              const dataArray = Object.values(data)
              setTimeout(() => {
                dataArray.forEach((item) => {
                  this.data.push(item)
                })
              }, 100)

              // this.page = res.data.pagination.current_page + 1
              // if (res.data.pagination.current_page === 1) {
              // const row_index = this.columnsLabel.map((e) => {
              //   return e.field
              // }).indexOf('row')
              // this.columnsLabel[row_index].footer.value = res.data.pagination.total
              // }

              if (this.$refs.courtsPriceListTable) this.$refs.courtsPriceListTable.loadMoreStatus = ''
            })
                .catch((error) => {
                  if (this.$refs.courtsPriceListTable && !axios.isCancel(error)) this.$refs.courtsPriceListTable.loadMoreStatus = 'Danger'
                })
          }
        }, 400)
      },
      reloadCourtsList() {
        this.data = []
        this.page = 1
        this.endedList = false
        this.getCourtPrices()
        this.editCourtPricePromptStatus = false
      },
      consoleLog() {
        // console.log(event)
      },
      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .courts-price-list {
    &:not(.fixed-draggable-dynamic-table-wrapper-height) {
      height: 100%;
    }
  }
</style>
