<template>
  <vx-card class="court-profile-page"
           :class="{'main-box top-zero-radius': !courtId, 'no-box-shadow': courtId > 0}"
           v-if="court && Object.keys(court).length > 0">

    <profile-main-box :profile="getUserProfile"
                      :default-action="profileStatus"
                      :actionsList="actionsList"
                      @editCourt="handleChangeProfileStatus('editCourt')"
                      @courtPriceList="handleChangeProfileStatus('courtPriceList')"
                      @courtActivitiesLog="handleChangeProfileStatus('courtActivitiesLog')">

      <courts-price-list v-if="profileStatus === 'courtPriceList'" :court-id="courtId || parseFloat($route.params.id)" :court-name="getUserProfile.name" />

      <court-activities-log v-if="profileStatus === 'courtActivitiesLog'" :court-id="courtId || parseInt($route.params.id)" />

      <edit-court v-if="profileStatus === 'editCourt'" :court-id="courtId || parseFloat($route.params.id)"/>
    </profile-main-box>
  </vx-card>
</template>

<script>
  import ProfileMainBox from '@/components/profileMainBox/profileMainBox2.vue'
  import CustomIcon from '../../../../../components/customIcon/customIcon.vue'
  import CourtsPriceList from "../price/list/courtsPriceList.vue";
  import EditCourt from "@/views/admin/club/courts/edit/editCourt.vue";
  import CourtActivitiesLog from "@/views/admin/club/courts/activitiesLog/courtActivitiesLog.vue";
  import {getCourt} from '@/http/requests/club/courts'
  import {checkUserPermissions} from '@/assets/js/functions'
  import {getAvatarUrl} from "../../../../../assets/js/functions"

  export default {
    name: 'courtProfile',
    components: {CourtActivitiesLog, EditCourt, CourtsPriceList, CustomIcon, ProfileMainBox},
    props: {
      courtId: 0
    },
    metaInfo () {
      return {
        title: this.court ? this.$t('courts.profile.dynamicTitle', {name: this.court.general.name.value}) : this.$t('courts.profile.title')
      }
    },
    data () {
      return {
        profileStatus: 'courtPriceList',
        actions: {
          toolbar: [
            {
              id: {name: 'editCourt', params: {id: this.$route.params.id}},
              type: 'link',
              icon: 'EDIT',
              iconPack: 'useral',
              color: 'warning',
              permission: 'court.update'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'courts'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        },
        court: null,
        actionsList: [
          {
            event: 'editCourt',
            name: 'editCourt',
            // params: {id: this.termId || this.$route.params.id},
            header: '',
            i18n: 'courts.profile.actions.edit',
            icon: 'EDIT',
            iconPack: 'useral',
            permission: 'court.update'
          },
          {
            event: 'courtPriceList',
            name: 'courtPriceList',
            // params: {id: this.termId || this.$route.params.id},
            header: '',
            i18n: 'courts.profile.actions.price',
            icon: 'DOLLAR_SIGN',
            iconPack: 'useral',
            permission: 'court.update'
          },
          {
            event: 'courtActivitiesLog',
            name: 'courtActivitiesLog',
            params: {id: this.courtId || this.$route.params.id},
            header: this.$t('courts.profile.actions.logs'),
            i18n: '',
            icon: 'LAST_ACTIVITIES',
            iconPack: 'useral',
            permission: 'activity_logs.show'
          }
        ]
      }
    },
    created () {
      if (!this.courtId) {
        setTimeout(() => {
          this.$store.dispatch('updateNavbarActions', this.actions)
          this.$store.dispatch('updateContentNavbarStyle', 'sticky')
          this.$store.dispatch('updateContentNavbarClass', 'profile-page-navbar mx-auto')
        }, 50)
      }

      this.getCourt()
    },
    computed: {
      getUserProfile () {
        const court = {
          avatar: this.court.avatar ? getAvatarUrl(this.court.avatar) : require('@/assets/images/no-image.png'),
          name: `${this.court.general.name.value}`,
          general: this.court.general,
          importantData: [
            /*{
              value: this.court.general.type,
              key: this.$t('courts.profile.asiderAvatar.type')
            }*/
          ],
          details: []
        }

        return court
      }
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getCourt () {

        getCourt(this.courtId || this.$route.params.id).then(res => {
          const court = res.data.data
          let courtType = ''
          if (court.type === 1) {
            courtType = this.$t('courts.types.hard')
          } else if (court.type === 2) {
            courtType = this.$t('courts.types.ground')
          } else if (court.type === 3) {
            courtType = this.$t('courts.types.grass')
          }

          this.court = {
            general: {
              name: {
                value: court.name,
                isInvalid: ''
              },
              type: courtType
            },
            avatar: court.avatar || ''
          }

          if (!this.courtId) {
            this.$store.dispatch('setPageTitle', this.$t('courts.profile.dynamicTitle', {name: this.court.general.name.value}))
          }
        })
      },
      handleChangeProfileStatus(status) {
        if (status !== this.profileStatus) {
          this.profileStatus = ''
          this.$nextTick(() => {
            this.profileStatus = status
          })
        }
      },
      handleClick (id) {
        document.getElementById(id).click()
      },
      printPage () {
        window.print()
      }
    }
  }
</script>

<style lang="scss">
  .court-profile-page {

    .vx-card__body {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  @media print {
    .con-vs-dialog.very-big-prompt {
      .vs-dialog {
        height: 99% !important;
        width: 99% !important;
        max-width: 99% !important;
      }
    }
  }

  @page {
    margin: 0.5mm;
  }
</style>
